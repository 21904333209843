import { apiUrl } from "src/config/host";
import axios from "axios";
import { toast } from "react-toastify";
import { urlBuilder } from "src/helpers/urlBuilder";
import { logoutRedirect } from "./fetch";

const refreshTokenVerify = async () => {
  const refreshTokenRes = await fetch(`${apiUrl}/portal/refresh-token/verify`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
    }),
    body: JSON.stringify({}),
  });

  let refreshTokenData = {};

  if (refreshTokenRes.status === 200) {
    refreshTokenData = await refreshTokenRes.json();
    localStorage.setItem("email", refreshTokenData?.email);
    localStorage.setItem("accessToken", refreshTokenData.access_token);
    localStorage.setItem("refreshToken", refreshTokenData.refresh_token);
    localStorage.setItem("isSubDomain", refreshTokenData?.isSubDomain);
  }

  return { refreshTokenRes, refreshTokenData };
};

const builderQueryString = (queries = []) => {
  let queryString = "";
  if (queries) {
    const filteredQueries = Object.fromEntries(Object.entries(queries).filter(([key, value]) => value != null));
    queryString = new URLSearchParams(filteredQueries);
    queryString = "?" + queryString.toString();
  }

  return queryString;
};

const ongoingRequests = new Map(); // Map to track ongoing requests by dynamic URL

const apiRequest = async (method = "put", url = "", payload = { body: null, params: {}, queries: null }, options = { useCustomUrl: false, responseType: "json", showToastMessage: false, signal: null, headers: {} }) => {
  let params = payload.params;
  delete payload.params;

  let defaultPayload = { body: null, queries: null };
  payload = { ...payload, defaultPayload };

  let defaultOptions = { useCustomUrl: false, showToastMessage: false, signal: null, headers: {} };
  options = { ...options, defaultOptions };

  try {
    let queryString = "";
    if (payload.queries != null) {
      queryString = builderQueryString(payload?.queries);
    }

    let accessToken = localStorage.getItem("accessToken") || payload.body?.token || options.headers?.Authorization || null;

    let headers = { "Content-Type": "application/json", Accept: "application/json" };

    headers = {
      ...headers,
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
      ...options.headers,
    };

    // Construct the dynamic URL
    const dynamicUrl = urlBuilder(url, params || {});

    // Check if there's an ongoing request for the same dynamic URL
    if (ongoingRequests.has(dynamicUrl)) {
      // Abort the ongoing request associated with the dynamic URL
      ongoingRequests.get(dynamicUrl).abort();
    }

    const controller = new AbortController();
    ongoingRequests.set(dynamicUrl, controller);

    const signal = options.signal || controller.signal;

    let request = axios.create({
      baseURL: options.useCustomUrl ? "" : apiUrl,
      responseType: options.responseType || "json",
      headers,
      signal,
    });

    let res = await request[method.toLowerCase()](`${urlBuilder(url, params)}${queryString}`, payload.body);

    if (res.status === 401 && res.data?.expired) {
      const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify();

      if (refreshTokenRes.status === 200) {
        headers.Authorization = refreshTokenData.refresh_token;

        request = axios.create({
          baseURL: options.useCustomUrl ? "" : apiUrl,
          headers,
        });

        res = await request[method.toLowerCase()](`${urlBuilder(url, params)}${queryString}`, payload.body, { signal: options.signal });
      }
    }

    if (res.status >= 200 && res.status < 300) {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.success(res.data?.message);
        }
        if (res.data?.data?.message) {
          toast.success(res.data?.data?.message);
        }
      }
      ongoingRequests.delete(dynamicUrl); // Remove the dynamic URL from ongoing requests
      return res;
    } else {
      if (options.showToastMessage) {
        if (res.data?.message) {
          toast.error(res.data?.message);
        }
        if (res.data?.data?.message) {
          toast.error(res.data?.data?.message);
        }
      }
      throw new Error(res?.message || res);
    }
  } catch (error) {
    if (error?.response?.status === 401 && error?.response?.data?.expired) {
      const queryString = builderQueryString(payload?.queries);

      const { refreshTokenRes, refreshTokenData } = await refreshTokenVerify();

      if (refreshTokenRes.status === 200) {
        let headers = { "Content-Type": "application/json", Accept: "application/json" };

        headers = {
          ...headers,
          Authorization: `Bearer ${refreshTokenData.refresh_token}`,
          ...options.headers,
        };

        const request = axios.create({
          baseURL: options.useCustomUrl ? "" : apiUrl,
          headers,
        });

        const res = await request[method.toLowerCase()](`${urlBuilder(url, params)}${queryString}`, payload.body, { signal: options.signal });

        if (res.status === 200) {
          if (options.showToastMessage) {
            if (res.data?.message) {
              toast.success(res.data?.message);
            }
            if (res.data?.data?.message) {
              toast.success(res.data?.data?.message);
            }
          }
          return res;
        } else {
          if (options.showToastMessage) {
            if (res.data?.message) {
              toast.error(res.data?.message);
            }
            if (res.data?.data?.message) {
              toast.error(res.data?.data?.message);
            }
          }
          throw new Error(res?.message || res);
        }
      } else {
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('refresh_token')

        // window.location.href = '/login'

        if (options.showToastMessage) {
          toast.error(error?.response?.data.message || error?.response?.data);
        }
        throw new Error(error?.response?.data.message || error?.response?.data || error);
      }
    } else if (error?.response?.status === 401 && error?.response?.data?.invalid) {
      logoutRedirect();
    } else {
      if (options.showToastMessage) {
        toast.error(error?.response?.data.message || error?.response?.data);
      }
      throw new Error(error?.response?.data.message || error?.response?.data || error);
    }
  }
};

export { builderQueryString, apiRequest };
